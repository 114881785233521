import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { mergePrismicPreviewData } from 'gatsby-source-prismic';
import { Layout, ProjectFilter, Section } from 'components';
import TwoColsProjectGallery from '../slices/TwoColsProjectGallery';

const IS_BROWSER = typeof window !== 'undefined';

const Gallery = ({ location, data: staticData }) => {
  const [filterValue, setFilterValue] = useState('ALL');

  const previewData = IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__;
  const data = mergePrismicPreviewData({ staticData, previewData });

  const { gallery } = data;
  const { nodes } = gallery;

  const galleryGridData = { data: { primary: gallery, items: nodes } };
  const isFilms = process.env.GATSBY_SITE_NAME === 'films';

  const bgElements = {
    writingRight: true,
    yellowSplatter: true,
    pinkGreyGraffiti: true,
  };

  const seo = {
    title: 'Work we did',
  };

  return (
    <Layout location={location} isFilms={isFilms} bgElements={bgElements} seo={seo}>
      <div style={{ backgroundColor: isFilms && 'white' }}>
        <ProjectFilter setFilterValue={setFilterValue} filterValue={filterValue} isFilms={isFilms} />
      </div>
      <TwoColsProjectGallery {...galleryGridData} isGalleryType filterValue={filterValue} isFilms={isFilms} />
    </Layout>
  );
};

export default Gallery;

export const pageQuery = graphql`
  query projects {
    gallery: allPrismicProjects(sort: { fields: data___publish_date, order: DESC }) {
      nodes {
        url
        data {
          page_type
          publish_date
          client {
            html
            text
          }
          director {
            html
            text
          }
          agency_client {
            html
            text
          }
          video_image {
            url
            localFile {
              id
              childImageSharp {
                gatsbyImageData(quality: 90, layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
          }
          video_link {
            url
          }
          video_title {
            html
            text
          }
          video_title_colour

          cta_link {
            url
          }
          cta_text {
            html
            text
          }
          description {
            html
            text
          }
          industries {
            industry {
              html
              text
            }
          }
          meta_description {
            html
            text
          }
          meta_title {
            html
            text
          }
          open_graph_image {
            url
          }
          products {
            product {
              html
              text
            }
          }
          professionals {
            profession {
              html
              text
            }
          }
          recognitions {
            recognition {
              html
              text
            }
          }
          schema {
            html
            text
          }
          services {
            service {
              html
              text
            }
            service_select
          }
          title {
            html
            text
          }
        }
      }
    }
  }
`;
